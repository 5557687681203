import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import {
  ReplaySubject,
  BehaviorSubject,
  Observable,
  lastValueFrom,
} from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { Announcement } from '@models/announcement';
import { AnnouncementDto } from '@interfaces/announcement';
import { ImageService } from './image.service';
import { SiteService } from './site.service';
import { Image } from '@models/image';
import { DateTime } from 'luxon';
import { PaginationInformation } from 'src/app/shared/pagination-server-side/datasources/genericBE.datasource';
import { CreateAnnouncementPayload } from '../interfaces/api';
import { Style } from '@models/style';

export interface SlideshowDuration {
  hours: number;
  minutes: number;
  seconds: number;
}

@Injectable({
  providedIn: 'root',
})
export class ManageService {
  private _announcements = new ReplaySubject<Announcement[]>();
  public readonly announcements = this._announcements.asObservable();

  private _isGettingAnnouncements = new BehaviorSubject<boolean>(false);
  public readonly isGettingAnnouncements =
    this._isGettingAnnouncements.asObservable();

  public loadingAnnouncements = false;

  public defaultAnnouncement = new Announcement({
    isGenerated: true,
    title: 'Have a great day!',
    content: 'No active announcements.',
    style: 4,
  });

  constructor(
    private http: HttpClient,
    public imageService: ImageService,
    public siteService: SiteService,
  ) {}

  public calcSlideshowDuration(
    announcements: Announcement[],
  ): SlideshowDuration {
    let durationInSecs = announcements.reduce((acc, announcement) => {
      return acc + announcement.displayDuration;
    }, 0);

    const hours = Math.floor(durationInSecs / 3600);
    durationInSecs %= 3600;
    const minutes = Math.floor(durationInSecs / 60);
    const seconds = durationInSecs % 60;

    return {
      hours: hours,
      minutes: minutes,
      seconds: seconds,
    };
  }

  public getEntryById(id: number): Promise<Announcement> {
    const url = environment.apiUrl.concat(
      '/api/v1/announcement/',
      id.toString(),
    );

    try {
      return <Promise<Announcement>>(
        lastValueFrom(
          this.http
            .get(url)
            .pipe(
              map(
                (announcement: AnnouncementDto) =>
                  new Announcement(announcement),
              ),
            ),
        )
      );
    } catch (err) {
      console.error('ERROR', err);
      return;
    }
  }

  public refreshAnnouncements(
    propertyId: number,
    timeframe: string,
    showLoading: boolean,
  ) {
    if (!propertyId) {
      return;
    }
    this.loadingAnnouncements = true;

    this.getAnnouncementsByPropertyId(
      propertyId,
      timeframe,
      showLoading,
    ).subscribe((announcements) => {
      this._announcements.next(announcements);
      this.loadingAnnouncements = false;
    });
  }

  public getAnnouncementsByPropertyId(
    propertyId: number,
    timeframe: string,
    showLoading: boolean,
  ): Observable<Announcement[]> {
    const url = environment.apiUrl.concat(
      '/api/v1/announcements/',
      timeframe,
      '/',
      propertyId.toString(),
    );
    this._isGettingAnnouncements.next(showLoading);
    return this.http.get<AnnouncementDto[]>(url).pipe(
      map((announcements: AnnouncementDto[]) => {
        let updatedAnnouncements = announcements;
        if (!announcements || announcements.length === 0) {
          updatedAnnouncements = [this.defaultAnnouncement];
        }
        return updatedAnnouncements.map(
          (announcement) => new Announcement(announcement),
        );
      }),
      tap(() => this._isGettingAnnouncements.next(false)),
      catchError((err) => {
        console.error('ERROR', err);
        return [this.defaultAnnouncement];
      }),
    ) as Observable<Announcement[]>;
  }

  public getSiteAnnouncementsPaginated(
    queryParams: HttpParams,
    pagination?: PaginationInformation,
  ): Observable<{
    data: Array<Announcement>;
    totalCount: number;
  }> {
    const { status, search } = pagination.filters;
    let active = null;
    let direction = null;

    if (pagination?.sort) {
      // "description" matches up with "content" in the DB.
      active =
        pagination.sort.active === 'description'
          ? 'content'
          : pagination.sort.active;
      direction = pagination.sort.direction;
    }
    if (status) {
      queryParams = queryParams.append('status', status as string);

      if (status === 'active') {
        // active status is any announcement that starts displaying before now AND stops displaying now or later than now
        queryParams = queryParams.append('startShowing', new Date().getTime());
        queryParams = queryParams.append('stopShowing', new Date().getTime());
      } else if (status === 'past') {
        queryParams = queryParams.append('stopShowing', new Date().getTime());
      } else if (status === 'future') {
        queryParams = queryParams.append('startShowing', new Date().getTime());
      }
    }
    if (search.trim().length > 0) {
      queryParams = queryParams.append('searchBy', '["title", "content"]');
    }
    if (active) {
      queryParams = queryParams.append('sortField', active as string);
      queryParams = queryParams.append('sortOrder', direction.toLowerCase());
    }
    const url = `${environment.apiv3Url}/announcements/site/${this.siteService.currentSite.id}`;
    this._isGettingAnnouncements.next(true);
    try {
      return this.http.get(url, { params: queryParams }).pipe(
        tap((response: { data: Array<Announcement>; totalCount }) => {
          if (
            status === 'active' &&
            search.length === 0 &&
            (!response.data || response.data.length === 0)
          ) {
            // If no active data, show default announcement
            response.data = [new Announcement(this.defaultAnnouncement)];
          } else {
            if (status === 'active') {
              const filterData = response.data.filter(
                (slide) =>
                  (slide.style as Style).name === 'Special Announcement',
              );
              response.data =
                filterData.length > 0 ? filterData : response.data;
            }
            response.data = response.data.map(
              (announcement: AnnouncementDto) => {
                const start = !announcement.startShowing
                  ? announcement.startShowing
                  : DateTime.fromISO(announcement.startShowing.toString());

                const end = !announcement.stopShowing
                  ? announcement.stopShowing
                  : DateTime.fromISO(announcement.stopShowing.toString());
                return new Announcement({
                  ...announcement,
                  startShowing: start,
                  stopShowing: end,
                });
              },
            );
          }
          this._isGettingAnnouncements.next(false);
        }),
      );
    } catch (err) {
      console.error('ERROR', err);
      this._isGettingAnnouncements.next(false);

      return;
    }
  }

  /**
   * Returns new entry from db
   * @param announcement
   */

  public saveAnnouncement(
    announcement: CreateAnnouncementPayload,
  ): Promise<Announcement> {
    const url = `${environment.apiv3Url}/announcements`;
    const body = announcement;

    try {
      return <Promise<Announcement>>lastValueFrom(
        this.http.post(url, JSON.stringify(body), {
          headers: { 'Content-Type': 'application/json' },
        }),
      ).then((response) => new Announcement(response));
    } catch (err) {
      console.error('ERROR', err);
      return;
    }
  }

  /**
   * Update existing announcement
   * @param announcement
   */
  public updateAnnouncement(
    announcement: CreateAnnouncementPayload,
    id: number,
  ): Promise<Announcement> {
    const url = `${environment.apiv3Url}/announcements/update/${id}`;
    const body = announcement;

    try {
      return <Promise<Announcement>>lastValueFrom(
        this.http.put(url, JSON.stringify(body), {
          headers: { 'Content-Type': 'application/json' },
        }),
      ).then((response) => new Announcement(response));
    } catch (err) {
      console.error('ERROR', err);
      return;
    }
  }

  /**
   * Save bulk announcements (array of Announcements)
   * @param announcements
   */
  public saveBulkAnnouncements(
    announcements: CreateAnnouncementPayload[],
  ): Promise<AnnouncementDto> {
    const url = `${environment.apiv3Url}/announcements/bulk`;
    const body = announcements;
    try {
      return <Promise<AnnouncementDto>>lastValueFrom(
        this.http.post(url, JSON.stringify(body), {
          headers: { 'Content-Type': 'application/json' },
        }),
      );
    } catch (err) {
      console.error('ERROR', err);
      return;
    }
  }

  /**
   * Update bulk announcements (array of Announcements)
   * @param announcements
   */
  public updateBulkAnnouncements(
    announcements: CreateAnnouncementPayload[],
  ): Promise<AnnouncementDto> {
    const url = `${environment.apiv3Url}/announcements/bulk`;
    const body = announcements;
    try {
      return <Promise<AnnouncementDto>>lastValueFrom(
        this.http.post(url, JSON.stringify(body), {
          headers: { 'Content-Type': 'application/json' },
        }),
      );
    } catch (err) {
      console.error('ERROR', err);
      return;
    }
  }

  /**
   * Update bulk announcements (array of Announcements)
   * @param announcements
   */
  public deleteBulkAnnouncements(
    announcements: Announcement[],
  ): Promise<AnnouncementDto> {
    const url = environment.apiUrl.concat('/api/v1/announcements/bulk/delete');
    const body = announcements;
    try {
      return <Promise<AnnouncementDto>>lastValueFrom(
        this.http.post(url, JSON.stringify(body), {
          headers: { 'Content-Type': 'application/json' },
        }),
      );
    } catch (err) {
      console.error('ERROR', err);
      return;
    }
  }

  public deleteAnnouncement(announcement: Announcement): Promise<any> {
    const url = environment.apiUrl
      .concat('/api/v1/announcement/')
      .concat(String(announcement.id));
    const body = announcement;

    try {
      return <Promise<any>>(
        lastValueFrom(this.http.put(url, {}, { responseType: 'text' }))
      );
    } catch (err) {
      console.error('ERROR', err);
      return;
    }
  }

  public createImageRecord(
    downloadUrl: string,
    path: string,
    siteId: number,
    styleId: number,
    announcementId?: number,
    position?: number,
  ) {
    const url = environment.apiUrl.concat('/api/v1/image');

    const body = {
      image: {
        path,
        position,
        downloadUrl: downloadUrl,
        site: siteId,
        style: styleId,
        announcement: announcementId,
      },
    };
    try {
      return <Promise<Image>>lastValueFrom(
        this.http
          .post(url, JSON.stringify(body), {
            headers: { 'Content-Type': 'application/json' },
          })
          .pipe(map((image) => new Image(image))),
      );
    } catch (err) {
      console.error('ERROR', err);
      return;
    }
  }

  // TODO max title & description values should be stored
  // in the database when we whitelabel this product
  public getMaxTitleChars(styleId: number): number {
    switch (styleId) {
      case 7:
        // Name in Lights
        return 100;
      case 12:
        // Image-Centric
        return 28;
      case 13:
        // Collage
        return 50;
      default:
        return 60;
    }
  }

  public getMaxDescriptionChars(styleId: number): number {
    switch (styleId) {
      case 3:
        // Event
        return 200;
      case 7:
        // Name in Lights
        return 500;
      case 12:
        // Image-Centric
        return 50;
      case 13:
        // Collage
        return 200;
      default:
        return 250;
    }
  }
}
