<!-- HEADER TEXT AND ACTIONS -->
<div class="flex justify-between items-center mb-6">
  <div>
    <button
      (click)="stepIndex === 0 ? goBack() : stepper.previous()"
      mat-flat-button
      color="accent"
    >
      Back
    </button>
  </div>
  <h1 class="text-2xl">{{ activeStep.title }}</h1>
  <div></div>
</div>

<mat-stepper
  labelPosition="bottom"
  headerPosition="bottom"
  animationDuration="0"
  (selectedIndexChange)="stepChanged($event)"
  #stepper
  disableRipple="true"
>
  <mat-step
    label="Format"
    [completed]="stepIndex > 0"
    [editable]="stepIndex > 0"
  >
    <div class="flex gap-4">
      <div class="min-w-[400px] max-w-[400px] mt-2 mb-4">
        <mat-card class="p-4 w-full h-full">
          <app-slide-format
            [form]="form.controls['format']"
            [announcement]="previewData"
          ></app-slide-format>
          <div class="flex justify-center w-full">
            <button
              mat-flat-button
              color="accent"
              [disabled]="!currentStepIsValid"
              matStepperNext
              data-qa="announcement-button-next"
            >
              Next
            </button>
          </div>
        </mat-card>
      </div>
      <ng-container *ngTemplateOutlet="preview"></ng-container>
    </div>
  </mat-step>
  <mat-step
    label="Description"
    [completed]="stepIndex > 1"
    [editable]="stepIndex > 1"
  >
    <div class="flex gap-4">
      <div class="min-w-[400px] max-w-[400px] mt-2 mb-4">
        <mat-card class="p-4 w-full h-full">
          <ng-container *ngIf="previewData.isCollage">
            <app-slide-description-collage
              [form]="form.controls['contentCollage']"
              [announcement]="previewData"
            ></app-slide-description-collage>
          </ng-container>
          <ng-container *ngIf="previewData.isGif">
            <app-slide-description-gif
              [form]="form.controls['contentGif']"
              [announcement]="previewData"
            ></app-slide-description-gif>
          </ng-container>
          <ng-container
            *ngIf="
              !previewData.isIndividualSpotlight &&
              !previewData.isCollage &&
              !previewData.isGif
            "
          >
            <app-slide-description-default
              [form]="form.controls['description']"
              [announcement]="previewData"
            ></app-slide-description-default>
          </ng-container>
          <ng-container *ngIf="previewData.isIndividualSpotlight">
            <app-slide-description-individual-spotlight
              [form]="form.controls['contentIndividualSpotlight']"
              [announcement]="previewData"
            ></app-slide-description-individual-spotlight>
          </ng-container>
          <div class="flex justify-center w-full">
            <button
              mat-flat-button
              color="accent"
              [disabled]="!currentStepIsValid"
              [ngClass]="{ 'is-loading': isSubmitting && isLastStep }"
              data-qa="announcement-button-next"
              matStepperNext
            >
              Next
            </button>
          </div>
        </mat-card>
      </div>
      <ng-container *ngTemplateOutlet="preview"></ng-container>
    </div>
  </mat-step>
  <mat-step
    label="Finish"
    [completed]="stepIndex > 2"
    [editable]="stepIndex > 2"
  >
    <div class="flex gap-4">
      <div class="min-w-[400px] max-w-[400px] mt-2 mb-4">
        <mat-card class="p-4 w-full h-full">
          <app-slide-preferences
            [form]="form.controls['preferences']"
          ></app-slide-preferences>
          <div class="flex w-full justify-center">
            <button
              mat-flat-button
              color="accent"
              [disabled]="!currentStepIsValid"
              [ngClass]="{ 'is-loading': isSubmitting && isLastStep }"
              (click)="saveSlide()"
              data-qa="announcement-button-next"
            >
              Finish
            </button>
          </div>
        </mat-card>
      </div>
      <ng-container *ngTemplateOutlet="preview"></ng-container>
    </div>
  </mat-step>
</mat-stepper>

<ng-template #preview>
  <!-- PREVIEW -->
  <div
    class="box right position-relative h-full w-full"
    [ngClass]="{ 'is-loading': formIsLoading }"
  >
    <div
      class="previewer"
      *ngIf="!formIsLoading"
      [ngSwitch]="previewData?.style"
    >
      <app-display-individual-spotlight
        [showClock]="false"
        [spotlight]="previewData"
        *ngSwitchCase="style.IndividualSpotlight"
      ></app-display-individual-spotlight>
      <app-display-event
        [showClock]="false"
        [announcement]="previewData"
        *ngSwitchCase="style.ActivityAndEvents"
      ></app-display-event>
      <app-display-collage
        [announcement]="previewData"
        [showClock]="false"
        *ngSwitchCase="style.Collage"
      ></app-display-collage>
      <app-display-title-slide
        [announcement]="previewData"
        *ngSwitchCase="style.ImageCentric"
      ></app-display-title-slide>
      <app-display-gif-slide
        [announcement]="previewData"
        *ngSwitchCase="style.GifCentric"
      >
      </app-display-gif-slide>
      <app-display-name-in-lights-slide
        [announcement]="previewData"
        *ngSwitchCase="style.NameInLights"
      ></app-display-name-in-lights-slide>
      <app-display-announcement
        [showClock]="false"
        [announcement]="previewData"
        *ngSwitchDefault
      ></app-display-announcement>
    </div>
  </div>
</ng-template>
<!-- STEPPER
<div class="stepper">
  <ul class="steps is-horizontal has-content-centered has-gaps">
    <ng-container *ngFor="let step of steps">
      <li
        *ngIf="step.isValid"
        class="steps-segment"
        [ngClass]="{ 'is-active': activeStep.id === step.id }"
      >
        <span class="steps-marker"></span>
        <div class="steps-content">
          <p class="is-size-4">{{ step.stepperName }}</p>
        </div>
      </li>
    </ng-container>
  </ul>
</div> -->
