<form [formGroup]="form" className="">
  <label class="form-question">Style</label>
  <div class="form-group">
    <mat-radio-group formControlName="style" data-qa="slide-format-radio-style">
      <mat-radio-button
        *ngFor="let style of styles"
        [value]="style.id"
        [matTooltip]="style.description"
        data-qa="slide-format-radio-option-style"
      >
        {{ style.name }}
      </mat-radio-button>
    </mat-radio-group>
  </div>

  <div *ngIf="showCategories.getValue()">
    <label class="form-question" color="accent">Content Category</label>
    <div class="form-group image-form-group">
      <app-category-select
        [categoryControl]="form.controls['subcategory']"
        (changeHandler)="onSubcatSelect($event)"
      ></app-category-select>

      <mat-slide-toggle
        [checked]="useDefaultBackgroundImage"
        (change)="toggleDefaultBackground($event)"
        color="accent"
        >Use Default Background Image</mat-slide-toggle
      >
    </div>
  </div>

  <div *ngIf="!useDefaultBackgroundImage">
    <div *ngIf="showUploadTool">
      <!-- Background image upload input -->
      <div class="form-question">Upload New Image</div>
      <div class="form-group image image-upload">
        <div class="field">
          <div class="control">
            <input
              class="input"
              type="file"
              id="file_background"
              accept=".png,.jpg,.gif,.tif"
              (change)="uploadInputChangedForBackGround($event)"
            />
            <label for="file_background">
              <ngx-file-drop
                [dropZoneClassName]="
                  'ngx-file-drop-zone-square background-image'
                "
                (onFileDrop)="dropped($event)"
                multiple="false"
                directory="false"
                accept=".png,.jpg,.gif,.tif"
              >
                <ng-template
                  ngx-file-drop-content-tmp
                  let-openFileSelector="openFileSelector"
                >
                  <mat-spinner
                    *ngIf="loading; else upload"
                    color="accent"
                    diameter="32"
                  ></mat-spinner>
                  <ng-template #upload
                    ><mat-icon class="large" color="accent"
                      >cloud_upload</mat-icon
                    ></ng-template
                  >
                </ng-template>
              </ngx-file-drop>
            </label>
            <mat-progress-bar
              *ngIf="progress !== 0"
              [value]="progress"
              color="accent"
            ></mat-progress-bar>
          </div>
        </div>
      </div>
    </div>
    <label class="form-question">Background Images</label>
    <div class="form-group">
      <app-slide-image-grid
        (selectedImage)="setBackgroundImage($event)"
        [update]="updateTrigger"
      ></app-slide-image-grid>
    </div>
  </div>
</form>
