<mat-card appearance="outlined" class="min-w-full w-full h-full min-h-full m-0">
  <mat-card-header class="flex justify-between items-center">
    <mat-card-title>Create a New Batch of Events</mat-card-title>
    <button mat-icon-button (click)="triggerHideForm()">
      <mat-icon>close</mat-icon>
    </button>
  </mat-card-header>
  <form class="form flex gap-[15px]" [formGroup]="form">
    <div class="flex w-2/3">
      <mat-form-field appearance="outline" color="accent">
        <mat-label color="accent">Start time</mat-label>
        <input
          data-qa="batch-start-input"
          matInput
          required
          formControlName="eventStart"
          placeholder="00:00 PM"
          type="time"
          (blur)="startTimeBlur()"
          (focus)="startTimeFocus()"
        />
      </mat-form-field>
      <mat-form-field appearance="outline" color="accent">
        <mat-label color="accent">End time</mat-label>
        <input
          data-qa="batch-end-input"
          matInput
          required
          formControlName="eventEnd"
          placeholder="00:00 PM"
          type="time"
        />
      </mat-form-field>
      <app-category-select
        [isRequired]="true"
        [categoryControl]="form.controls['subcategory']"
        (changeHandler)="onSubcatSelect($event)"
      >
      </app-category-select>
      <div class="flex gap-[15px] items-center justify-center">
        <div>
          <h2 class="thumbnail-container-header">Digital Signage Background</h2>
          <div class="thumbnail-container">
            <img [src]="placeHolderImageUrl" />
          </div>
        </div>
        <div>
          <h2 class="icon-container-header">
            Digital Signage/Gigi Mobile App Icon
          </h2>
          <div *ngIf="placeHolderImageUrl" class="icon-container">
            <img [src]="'assets/icons/event-tag/' + categoryIcon + '.svg'" />
          </div>
        </div>
      </div>
      <mat-form-field class="stretch" appearance="outline" color="accent">
        <mat-label color="accent">Event Title</mat-label>
        <input
          data-qa="batch-event-title"
          matInput
          required
          formControlName="title"
          type="text"
          [maxLength]="45"
        />
        <mat-hint>{{ form?.controls?.title.value?.length || 0 }}/45</mat-hint>
      </mat-form-field>
      <mat-form-field class="stretch" appearance="outline" color="accent">
        <mat-label color="accent">Event Details</mat-label>
        <input
          matInput
          formControlName="content"
          type="text"
          [maxLength]="80"
        />
        <mat-hint>{{ form?.controls?.content.value?.length || 0 }}/80</mat-hint>
      </mat-form-field>
      <app-room-select
        class="stretch"
        palette="grey"
        [roomControl]="form.controls['room']"
        [locationControl]="form.controls['location']"
        [videoUrlControl]="form.controls['videoUrl']"
      >
      </app-room-select>
      <app-event-type-select [eventTypeControl]="form.controls['eventType']">
      </app-event-type-select>
    </div>

    <div class="flex flex-col gap-[15px] w-1/3">
      <div>
        <app-multi-date-picker
          [displayDate]="selectedMonth"
          (datesSelectionChange)="onDatesSelectionChange($event)"
        >
        </app-multi-date-picker>
      </div>
      <div>
        <i>
          <small>Select which days to add this event.</small> <br />
          <br />
          <small>
            If all week days (e.g. all Mondays) of the month have the same
            event, it will populate into the next month if you choose to "Copy
            Last Month."
          </small>
        </i>
      </div>
    </div>
  </form>

  <div class="flex justify-end items-center gap-[10px]">
    <div>
      <button mat-button (click)="clear()">Clear</button>
    </div>
    <div>
      <app-progress-button
        data-qa="batch-form-submit"
        color="accent"
        text="Save"
        (submit)="save()"
        [disabled]="form.invalid"
        [submitting]="isLoading"
      >
      </app-progress-button>
    </div>
  </div>
</mat-card>
