import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnDestroy,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { BehaviorSubject } from 'rxjs';

import {
  SubcategoryService,
  CategoryListItem,
} from 'src/app/core/services/subcategory.service';
import { ImageService } from 'src/app/core/services/image.service';
import { SubcategoryDto } from '@interfaces/subcategory';

@AutoUnsubscribe()
@Component({
  selector: 'app-category-select',
  templateUrl: './category-select.component.html',
  styleUrls: ['./category-select.component.scss'],
})
export class CategorySelectComponent implements OnInit, OnDestroy {
  @Input() categoryControl: UntypedFormControl;
  @Input() inputControl: UntypedFormControl;
  @Output() changeHandler: EventEmitter<any> = new EventEmitter();
  @Input() inputHint = '';
  @Input() isRequired = false;
  public categories: CategoryListItem[];
  public filteredCategories: BehaviorSubject<CategoryListItem[]> =
    new BehaviorSubject([]);
  private somethingSelected = false;
  private subcategorySubscription;

  constructor(
    public subcategoryService: SubcategoryService,
    public imageService: ImageService,
  ) {}

  ngOnInit() {
    this.subcategorySubscription =
      this.subcategoryService.categoryList.subscribe(
        (categories: CategoryListItem[]) => {
          this.categories = categories;
          this.filteredCategories.next(categories);

          if (this.categoryControl.value) {
            this.selectSubcategory(this.categoryControl.value);
            this.somethingSelected = true;
          }
        },
      );
  }

  ngOnDestroy() {
    this.subcategorySubscription.unsubscribe();
  }

  selectSubcategory(subcategory: Partial<SubcategoryDto>) {
    this.categoryControl.setValue(subcategory);
  }

  // when a category is selected, we manually handle the change in the parent since there is often data that must be inferred based
  // on subcategory selection (ie, associated default image, etc)
  onCategorySelected(event) {
    this.selectSubcategory(event.value);
    this.changeHandler.emit(event.value);
    this.somethingSelected = true;
  }

  getOptionName(subcatObj: any): string {
    // defensive move for bad seed data
    if (subcatObj) {
      return subcatObj.name;
    } else {
      return '';
    }
  }

  filterCategories = (category: String) => {
    if (this.somethingSelected) {
      // if an option was previously selected, null the category control values
      this.somethingSelected = false;
      this.categoryControl.setValue(null);
      this.changeHandler.emit(null);
    }

    if (category) {
      const filterValue = category.toLowerCase();
      return this.categories.reduce((acc, c) => {
        const updatedSubcategories = c.subcategories.filter((subcategory) =>
          subcategory.name.toLowerCase().includes(filterValue),
        );
        if (updatedSubcategories.length > 0) {
          const updatedCategory = {
            ...c,
            subcategories: updatedSubcategories,
          };
          acc = [...acc, updatedCategory];
        }
        return acc;
      }, []);
    } else {
      // if query is empty, return full list of categories
      return this.categories;
    }
  };

  filterSubcategories(subcategory: String) {
    this.filteredCategories.next(this.filterCategories(subcategory));
  }
}
