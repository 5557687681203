import { Component, Input } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';

import { ManageService } from '../../../core/services/manage.service';
import { Announcement } from '@models/announcement';
import { Image } from '@models/image';

export interface FormValues {
  title: string;
  description: string;
  image: Image | number;
}
@Component({
  selector: 'app-slide-description-default',
  templateUrl: './slide-description-default.component.html',
  styleUrls: ['./slide-description-default.component.scss'],
})
export class SlideDescriptionDefaultComponent {
  @Input() public form: UntypedFormGroup;
  @Input() set announcement(announcement: Announcement) {
    if (
      announcement && announcement.style
    ) {
      this.maxTitleLength = this.manageService.getMaxTitleChars(announcement.styleId);
      this.maxDescriptionLength = this.manageService.getMaxDescriptionChars(announcement.styleId);
    }
  }

  public maxTitleLength: number;
  public maxDescriptionLength: number;

  static deserializeToForm(data: Announcement): FormValues {
    return {
      title: data.title,
      description: data.content,
      image: data.image,
    };
  }

  static serialize(form) {
    return {
      title: form.title,
      description: form.description,
      image: form.image,
    };
  }

  static formModel() {
    return {
      title: new UntypedFormControl(''),
      description: new UntypedFormControl(''),
      image: new UntypedFormControl(null),
    };
  }

  constructor(public manageService: ManageService) {}
}
