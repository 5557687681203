<div
  class="flex gap-[10px]"
  [class.flex-col]="direction === 'column' || palette !== 'grey'"
>
  <mat-form-field appearance="outline">
    <mat-label color="accent">Room</mat-label>
    <mat-select
      class="room-select"
      [(value)]="room"
      (selectionChange)="roomSelectChanged($event)"
      required
      data-qa="event-select-room"
    >
      <mat-option
        *ngFor="let room of rooms"
        [value]="room"
        data-qa="event-select-room-option"
      >
        {{ room.name }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field *ngIf="isCustomRoom" appearance="outline" color="accent">
    <mat-label color="accent">Custom Location Name</mat-label>
    <input
      matInput
      [maxLength]="30"
      [class.input]="palette === 'blue'"
      [formControl]="locationControl"
      data-qa="event-input-room-name"
      required
    />
    <mat-error
      *ngIf="locationControl.hasError('required') && locationControl.touched"
    >
      Custom Room name is required
    </mat-error>
    <mat-hint>{{ locationControl?.value?.length || 0 }}/30</mat-hint>
  </mat-form-field>
  <mat-form-field *ngIf="isVideoRoom" appearance="outline" color="accent">
    <mat-label color="accent">Video URL</mat-label>
    <input
      autofocus
      matInput
      required
      [formControl]="videoUrlControl"
      placeholder="https://vimeo.com/134221761"
      data-qa="event-input-room-url"
      type="url"
    />
    <mat-error
      *ngIf="videoUrlControl.hasError('pattern') && videoUrlControl.touched"
    >
      Please insert valid Video URL
    </mat-error>
    <mat-error
      *ngIf="videoUrlControl.hasError('required') && videoUrlControl.touched"
    >
      Video URL is required
    </mat-error>
  </mat-form-field>
</div>
