import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { BehaviorSubject, Subscription } from 'rxjs';
import { distinctUntilChanged, filter } from 'rxjs/operators';
import { FileSystemFileEntry, NgxFileDropEntry } from 'ngx-file-drop';

import { Announcement } from '@models/announcement';
import { ManageService } from 'src/app/core/services/manage.service';
import { ImageService } from 'src/app/core/services/image.service';
import { Image } from '@models/image';
import { AnnouncementType } from '../../../../../../core/enums/announcement-type';
import { SubcategoryDto } from '@interfaces/subcategory';
import { CategoryService } from 'src/app/core/services/category.service';
import { CategoryDto } from '@interfaces/category';
import { SiteService } from 'src/app/core/services/site.service';
import { Style } from '@models/style';
import { AlertService } from 'src/app/core/services/alert.service';
import { SubcategoryService } from 'src/app/core/services/subcategory.service';

export interface FormValues {
  style: Number;
  category: CategoryDto;
  subcategory: SubcategoryDto;
  background: Image | number;
}

@AutoUnsubscribe()
@Component({
  selector: 'app-slide-format',
  templateUrl: './slide-format.component.html',
  styleUrls: ['./slide-format.component.scss'],
})
export class SlideFormatComponent implements OnInit, OnDestroy {
  @Input() public form: UntypedFormGroup;
  @Input() public announcement: Partial<Announcement>;
  public styles: Style[];
  public useDefaultBackgroundImage = true;
  public showCategories = new BehaviorSubject<boolean>(true);
  public showUploadTool = false;
  public updateTrigger = 0;
  public loading = false;
  public progress = 0;

  private _subscriptions: Array<Subscription> = [];

  static serialize(form: FormValues): Partial<Announcement> | any {
    return form;
  }

  static deserializeToForm(data: Announcement): FormValues {
    console.log('deserialize in slide format', data);
    return {
      style: data.styleId,
      category: data.category,
      subcategory: data.subcategory,
      background: data.background,
    };
  }

  static formModel() {
    return {
      style: new UntypedFormControl(null, Validators.required),
      category: new UntypedFormControl(null, Validators.required),
      subcategory: new UntypedFormControl(null, Validators.required),
      background: new UntypedFormControl(null, Validators.required),
    };
  }

  constructor(
    public manageService: ManageService,
    public siteService: SiteService,
    public imageService: ImageService,
    public categoryService: CategoryService,
    public subcategoryService: SubcategoryService,
    private alertService: AlertService,
  ) {}

  ngOnInit() {
    // validator set at runtime to leverage the subcategory service values
    // must run update after setting validator at runtime
    console.log('announcement', this.announcement);
    if (this.announcement.subcategory) {
      this.form.patchValue({
        category: this.announcement.category,
        subcategory: this.announcement.subcategory,
      });
    }

    const styleId =
      this.announcement.style && (this.announcement.style as Style).id
        ? (this.announcement.style as Style).id
        : 4;
    this.form.patchValue({ style: styleId });
    this.listenForStyles();
  }

  ngOnDestroy() {
    if (this._subscriptions.length > 0) {
      this._subscriptions.forEach((subscription) => subscription.unsubscribe());
    }
  }

  private listenForStyles(): void {
    this._subscriptions.push(
      this.siteService.styles
        .pipe(distinctUntilChanged())
        .subscribe((styles) => {
          const unallowedStyles = ['GIF Centric', 'Name in Lights'];
          const seniors = ['Senior Living', 'Senior Living/Residential'];
          this.styles = styles
            .filter((style) => style.name !== 'Activities & Events')
            .filter((style) => {
              if (seniors.includes(this.siteService.currentSiteType)) {
                if (unallowedStyles.includes(style.name)) {
                  return false;
                }
              }
              return true;
            });

          console.log('styles', this.styles);
        }),
    );
    this._subscriptions.push(
      this.siteService.siteType
        .pipe(
          filter((siteType) => !!siteType),
          distinctUntilChanged(),
        )
        .subscribe((type) => {
          console.log('site type', type);
          if (type === 'Senior Living') {
            this.showUploadTool = false;
            this.setCategoriesShowing();
          } else if (type === 'Corporate') {
            this.showUploadTool = true;
            this.setCategoriesHidden();
          } else if (type === 'Senior Living/Residential') {
            this.showUploadTool = true;
            this.setCategoriesShowing();
          } else {
            this.showUploadTool = false;
            this.setCategoriesHidden();
          }
        }),
    );
  }

  setCategoriesHidden(): void {
    this.showCategories.next(false);
    this.useDefaultBackgroundImage = false;
    this.form.patchValue({ category: 13, subcategory: 31 });
  }

  setCategoriesShowing(): void {
    this.showCategories.next(true);
    this.useDefaultBackgroundImage = true;
  }

  setBackgroundImage(background: Image) {
    this.form.patchValue({ background: background });
  }

  toggleDefaultBackground(event) {
    this.useDefaultBackgroundImage = event.checked;
  }

  public dropped(files: NgxFileDropEntry[]) {
    const droppedFile = files[0]; // only support one file upload
    if (droppedFile.fileEntry.isFile) {
      const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
      fileEntry.file((file: File) => {
        this.handleBackgroundImageFileChange(file);
      });
    }
  }

  uploadInputChangedForBackGround(event) {
    const file: File = event.target.files[0];
    this.handleBackgroundImageFileChange(file);
  }

  // image resize accounts for roughly 2/3rd of the upload time
  public handleLoading = (value) => {
    this.progress = value * (2 / 3);
  };

  async handleBackgroundImageFileChange(file) {
    this.loading = true;
    if (await this.imageService.isAllowedFileType(file)) {
      const myReader: FileReader = new FileReader();
      const resize = await this.imageService.resizeFile(file, {
        maxWidthOrHeight: 3840, // should not be a higher resolution than 4k for digital signage
        maxSizeMB: 4,
        onProgress: this.handleLoading,
      });
      myReader.readAsDataURL(resize);

      myReader.onloadend = async (loadEvent: any) => {
        this.progress = 80;
        const imageBlob = this.imageService.dataURItoBlob(
          loadEvent.currentTarget.result,
        );
        const path = await this.imageService.uploadToFirestore(
          this.imageService.getSiteBackgroundImageFilePath(
            this.announcement.siteId,
          ),
          imageBlob,
        );
        if (path) {
          const downloadUrl = await this.imageService.getDownloadUrl(path);
          const response = await this.manageService.createImageRecord(
            downloadUrl,
            path,
            this.announcement.siteId,
            AnnouncementType.Announcement,
          );
          this.progress = 100;
          if (response && response.id) {
            // update value
            this.updateTrigger = this.updateTrigger + 1;
            this.setBackgroundImage(response);
          }
          this.loading = false;
          setTimeout(() => (this.progress = 0), 100);
        }
      };
    } else {
      this.alertService.error(
        'Please choose an allowed file type: jpg, png, gif or tif.',
      );
      this.progress = 0;
      this.loading = false;
    }
  }

  async setDefaultBackground(subcatId: number) {
    const defaultBackgroundImage: Image =
      await this.imageService.getBackgroundForSubcategory(subcatId);
    this.form.patchValue({ background: defaultBackgroundImage });
  }

  onSubcatSelect(subcategory: SubcategoryDto) {
    if (subcategory) {
      this.form.patchValue({ category: subcategory.parent });
      this.setDefaultBackground(subcategory.id);
      if (!this.useDefaultBackgroundImage) {
        this.useDefaultBackgroundImage = true;
      }
    } else {
      this.form.patchValue({
        category: null,
        background: null,
      });
    }
  }
}
