import { UntypedFormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { BehaviorSubject, EMPTY, combineLatest, timer } from 'rxjs';
import { debounce, distinctUntilChanged, startWith, tap } from 'rxjs/operators';

/**
 *
 * @description Helper function to create listeners for fetching data on a paginated table
 * @export
 * @template T
 * @param {FormGroup} filters form group
 * @param {MatSort} sort instance
 * @param {MatPaginator} paginator instance
 * @param {ServiceMethod<T>} method to load data in the datasource
 * @return {*}
 */
export default function bootstrapTable<T>(
  sort: MatSort,
  paginator: MatPaginator,
  dataSourceLoadData: ({ filters, sort, pagination }) => void,
  filters: UntypedFormGroup,
) {
  let isFirstLoad = true;
  // Listen for changes on the filter form group
  const filters$ = filters.valueChanges.pipe(
    debounce(() => {
      const returnValue = isFirstLoad ? EMPTY : timer(750);
      isFirstLoad = false;
      return returnValue;
    }),
    distinctUntilChanged((prev, curr) => {
      return JSON.stringify(prev) === JSON.stringify(curr);
    }),
    startWith(filters.value),
    tap(() => {
      // When we filter, reset the page index to 0
      paginator.firstPage();
      isFirstLoad = false;
    }),
  );

  // Listen for changes on sort
  const sortChange$ = sort
    ? sort.sortChange.pipe(
        startWith({
          active: sort.active,
          direction: sort.direction,
        }),
        tap((sort) => {
          // When we sort, reset the page index to 0
          paginator.firstPage();
        }),
      )
    : new BehaviorSubject(null).asObservable();

  // Listen for changes on pagination
  const paginatorPage$ = paginator.page.pipe(
    startWith({
      length: paginator.length,
      pageIndex: paginator.pageIndex,
      pageSize: paginator.pageSize,
    }),
  );

  console.log('sort', sort);

  dataSourceLoadData({
    filters: filters.value,
    sort: {
      active: sort?.active,
      direction: sort?.direction,
    },
    pagination: {
      length: paginator.length,
      pageIndex: paginator.pageIndex,
      pageSize: paginator.pageSize,
    },
  });

  // Combine all of them and get data when something changes
  return combineLatest<[any, any, any]>([
    filters$,
    sortChange$,
    paginatorPage$,
  ]).pipe(
    distinctUntilChanged(
      (
        [prevFilter, prevSort, prevPaginator],
        [currFilter, currSort, currPaginator],
      ) => {
        // optional manual refresh trigger
        if (prevFilter?.refreshTrigger !== currFilter?.refreshTrigger) {
          return false;
        }
        if (prevFilter?.inactive !== currFilter?.inactive) {
          return false;
        }
        if (prevFilter?.search !== currFilter?.search) {
          return false;
        }

        if (prevFilter?.status !== currFilter?.status) {
          return false;
        }
        if (
          prevSort?.active !== currSort?.active ||
          prevSort?.direction !== currSort?.direction
        ) {
          return false;
        }
        if (
          prevPaginator.length !== currPaginator.length ||
          prevPaginator.pageSize !== currPaginator.pageSize ||
          prevPaginator.pageIndex !== currPaginator.pageIndex
        ) {
          return false;
        }

        return true;
      },
    ),
    tap(([filters, sort, pagination]) => {
      dataSourceLoadData({
        filters,
        sort,
        pagination,
      });
    }),
  );
}
