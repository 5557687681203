import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Subject } from 'rxjs';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { takeUntil } from 'rxjs/operators';

import { Room } from '@models/room';
import { RoomService } from '../../../core/services/room.service';
import { urlRegex } from 'src/app/core/constants/regex';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'app-room-select',
  templateUrl: './room-select.component.html',
  styleUrls: ['./room-select.component.scss'],
})
export class RoomSelectComponent implements OnInit, OnDestroy {
  @Input() roomControl: UntypedFormControl;
  @Input() locationControl: UntypedFormControl;
  @Input() videoUrlControl: UntypedFormControl;
  @Input() palette: 'grey' | 'blue';
  @Input() direction: 'row' | 'column';
  private destroyed = new Subject<boolean>();

  rooms: Room[];
  room: Room; // Selected room

  constructor(private roomService: RoomService) {}

  ngOnInit() {
    // If editing an existing Event
    const initialLocation = this.locationControl.value;
    const initialRoom = this.roomControl.value;
    const initialVideoUrl = this.videoUrlControl.value;

    this.roomService.rooms
      .pipe(takeUntil(this.destroyed))
      .subscribe((rooms) => {
        this.rooms = rooms;

        // If initial room, set default value.
        // Need to find it in the rooms array so the object is identical as the rooms array version
        // (otherwise it won't match, and won't show a default value)
        if (initialRoom) {
          this.room = this.roomService.findRoomById(initialRoom.id, rooms);
        }

        this.updateControlValidators();
      });
  }

  ngOnDestroy() {
    this.destroyed.next(true);
  }

  public get isCustomRoom(): boolean {
    return this.room && Number(this.room.id) === 1;
  }

  public get isVideoRoom(): boolean {
    return this.room && Number(this.room.id) === 2;
  }

  // A defined room in the property's system
  public get isDefinedRoom(): boolean {
    return !this.isCustomRoom && !this.isVideoRoom;
  }

  public roomSelectChanged(item: MatSelectChange) {
    this.room = item.value;
    this.roomControl.setValue(item.value);

    // Reset location control on select change
    this.locationControl.setValue(null);

    // If not video room, clear Video URL
    if (!this.isVideoRoom) {
      this.videoUrlControl.setValue(null);
    }

    this.updateControlValidators();
  }

  private updateControlValidators(): void {
    this.updateVideoControlValidators();
    this.updateLocationControlValidators();
  }

  private updateVideoControlValidators(): void {
    // If Video is selected room
    if (this.isVideoRoom) {
      this.videoUrlControl.setValidators([
        Validators.pattern(urlRegex),
        Validators.required,
      ]);
    } else {
      this.videoUrlControl.clearValidators();
    }
    this.videoUrlControl.updateValueAndValidity();
  }

  private updateLocationControlValidators(): void {
    // If Custom Room is selected room
    if (this.isCustomRoom) {
      this.locationControl.setValidators([Validators.required]);
    } else {
      this.locationControl.clearValidators();
    }
    this.locationControl.updateValueAndValidity();
  }
}
